<template>
  <UiImage class="mobile-bonus-icon" src="/static/header/bonuses-mobile.png" />
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.mobile-bonus-icon {
  width: 100%;
  height: 100%;
}
</style>
