import {
  BOTTOM_TABS,
  SIDE_ACTIVITIES,
  SIDE_MENU_ITEMS,
  SIDE_ADDITIONAL_MENU,
  SIDE_SOCIALS,
  type THeaderMenuKeys,
  type THeaderMenuData,
} from '../constants/menuItems.constants';

export const useLinksStore = defineStore('header/links', () => {
  const {
    header: { customLinks, bottomMenu, sideMenu, desktopMenu },
  } = useProjectSettings();

  const _getParsedData = (data: THeaderMenuData[], checkKeys: THeaderMenuKeys[]) => {
    const result = [];

    for (const key of checkKeys) {
      const item = data.find((i) => i.key === key);
      if (item) result.push({ ...item, link: customLinks[key] ?? item.link });
    }

    return result;
  };

  const _getMenuItemsLocale = (item: THeaderMenuData) => ({ ...item, title: `header.menuItems.${item.key}` });
  const _getSideMenuLocale = (item: THeaderMenuData) => ({ ...item, title: `header.sideMenu.${item.key}` });
  const _getDesktopGameModesLocale = (item: THeaderMenuData) => ({
    ...item,
    title: `header.desktopGameModes.${item.key}`,
  });

  const bottomLeftItems = computed(() => _getParsedData(BOTTOM_TABS, bottomMenu.left).map(_getMenuItemsLocale));
  const bottomRightItems = computed(() => _getParsedData(BOTTOM_TABS, bottomMenu.right).map(_getMenuItemsLocale));

  const activities = computed(() => _getParsedData(SIDE_ACTIVITIES, sideMenu).map(_getSideMenuLocale));
  const menuItems = computed(() => _getParsedData(SIDE_MENU_ITEMS, sideMenu).map(_getSideMenuLocale));
  const additionalMenu = computed(() => _getParsedData(SIDE_ADDITIONAL_MENU, sideMenu).map(_getSideMenuLocale));
  const socials = computed(() => _getParsedData(SIDE_SOCIALS, sideMenu).map(_getSideMenuLocale));

  const desktopGameModes = computed(() => _getParsedData(SIDE_MENU_ITEMS, desktopMenu).map(_getDesktopGameModesLocale));

  return { bottomLeftItems, bottomRightItems, activities, menuItems, additionalMenu, socials, desktopGameModes };
});
