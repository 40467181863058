<template>
  <div class="side-item">
    <div class="side-item__main" @click="$emit('mainAreaClicked')">
      <div class="side-item__main-icon">
        <slot name="icon"></slot>
      </div>
      <div class="side-item__main-info">
        <span class="side-item__main-title">{{ title }}</span>
        <BaseNotification v-if="counter && counter.value" :value="counter.value" size="m" :type="counter.type" />
      </div>
      <slot name="main-content"></slot>
    </div>
    <div class="side-item__right" @click="$emit('rightAreaClicked')">
      <slot name="right-content">
        <SvgoHeaderSideArrow class="side-item__right-default" />
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ISideItemProps, ISideItemEmits } from './SideItem.types';

const props = defineProps<ISideItemProps>();
const { counter } = toRefs(props);

defineEmits<ISideItemEmits>();
</script>

<style scoped lang="scss" src="./SideItem.scss"></style>
