import type { IBaseNotificationProps } from '~/components/BaseNotification/BaseNotification.types';

import { useUserStore } from '~/store/user/user.store';
import { useBattlesDataStateStore } from '~/features/battles/store/dataState.store';
import { useBattlesListStore } from '~/features/battles/store/battlesList.store';

export const useBattlesNotificationsStore = defineStore('header/battles-notifications', () => {
  const battlesListStore = useBattlesListStore();
  const { battlesList } = storeToRefs(battlesListStore);

  const battlesDataStateStore = useBattlesDataStateStore();
  const { myState } = storeToRefs(battlesDataStateStore);

  const userStore = useUserStore();
  const { userId } = storeToRefs(userStore);

  watch(
    userId,
    (newUserId) => {
      if (!newUserId) return;
      battlesDataStateStore.fetchUserStatus();
    },
    { immediate: true },
  );

  const battles = ref<Omit<IBaseNotificationProps, 'size'>>({
    value: 0,
    type: 'black',
  });

  watchEffect(() => {
    if (myState.value.inBattle) {
      battles.value.value = 1;
      battles.value.type = 'red';
      return;
    }

    battles.value.value = battlesList.value.length;
    battles.value.type = 'black';
  });

  return { battles };
});
