export enum EBaseAvatarSizes {
  XXS = 'xxs',
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
  XXL = 'xxl',
}

export interface IBaseAvatarProps {
  image: string;
  progress: number;
  level: number;
  size: EBaseAvatarSizes;
}
