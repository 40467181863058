<template>
  <NuxtLinkLocale to="/" class="logo">
    <component :is="logoIcon" class="logo__main" />
    <SvgoPfTitle v-if="!isProvablyFairDisabled" class="logo__pf" @click.prevent="handlePfClick" />
    <div class="logo__shadow">
      <SvgoHeaderLogoShadow class="logo__shadow-icon" />
    </div>
  </NuxtLinkLocale>
</template>

<script setup lang="ts">
const { isProvablyFairDisabled } = useProjectSettings();

const changeXl = GlobalUtils.Media.changeByMedia('xl');
const changeMd = GlobalUtils.Media.changeByMedia('md');

const logoIcon = computed(() => `SvgoHeaderLogo${changeXl('L', changeMd('M', 'S'))}`);

const handlePfClick = () => navigateTo(useLocaleRoute()(ROUTING.PROVABLY_FAIR.SETTINGS));
</script>

<style scoped lang="scss" src="./Logo.scss"></style>
