<template>
  <div :class="['mobile-tab-item', rootClass]">
    <div class="mobile-tab-item__icon">
      <slot name="icon"></slot>
      <BaseNotification v-if="counter && counter.value !== 0" :value="counter.value" size="s" :type="counter.type" />
    </div>
    <span class="mobile-tab-item__title">{{ title }}</span>
  </div>
</template>

<script setup lang="ts">
import type { IMobileTabItemProps } from './MobileTabItem.types';

const props = defineProps<IMobileTabItemProps>();

const rootClass = computed(() => ({
  [`mobile-tab-item--no-effects`]: props.noEffects,
}));
</script>

<style scoped lang="scss" src="./MobileTabItem.scss"></style>
