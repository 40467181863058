<template>
  <div class="desktop-icon-button">
    <div class="desktop-icon-button__content" :style="contentStyles">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IDesktopIconButtonProps } from './DesktopIconButton.types';

const props = defineProps<IDesktopIconButtonProps>();
const { color, shadow } = toRefs(props);

const contentStyles = computed(() => ({
  '--icon-color': color.value,
  '--icon-shadow': shadow.value,
}));
</script>

<style scoped lang="scss" src="./DesktopIconButton.scss"></style>
