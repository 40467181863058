import type { IBaseNotificationProps } from '~/components/BaseNotification/BaseNotification.types';
import { useUserStore } from '~/store/user/user.store';

export const useDailyNotificationsStore = defineStore('header/daily-notifications', () => {
  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);

  const dailyNotifications = ref<Omit<IBaseNotificationProps, 'size'>>({
    value: 0,
    type: 'red',
  });

  const dailyTimer = ref('');

  watch(
    user,
    (newUser) => {
      if (!newUser) return;

      const { availableCases, nearestCaseTime } = newUser;

      if (nearestCaseTime) {
        dailyNotifications.value.value = 0;

        const { timerData, start } = useTimer(toRef(nearestCaseTime * 1000));
        start();

        const unwatch = watch(
          timerData,
          ({ hours, minutes, seconds }) => {
            if (+hours || +minutes || +seconds) {
              dailyTimer.value = `${hours}:${minutes}:${seconds}`;
              return;
            }

            unwatch();
            dailyTimer.value = '';
          },
          { deep: true, immediate: true },
        );

        return;
      }

      dailyTimer.value = '';
      dailyNotifications.value.value = availableCases.length;
    },
    {
      immediate: true,
      deep: true,
    },
  );

  return { dailyNotifications, dailyTimer };
});
