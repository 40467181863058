<template>
  <SkeletonComponentLoader>
    <section v-if="!isLoaded" :class="['desktop-skeleton', skeletonClass]">
      <div class="desktop-skeleton__top">
        <div class="desktop-skeleton__top-left">
          <SkeletonBaseForeground :width="40" v-bind="skeletonConfig" />
          <SkeletonBaseForeground v-for="i in 2" :key="i" :width="140" v-bind="skeletonConfig" />
        </div>
        <div class="desktop-skeleton__top-right">
          <SkeletonBaseForeground :width="140" v-bind="skeletonConfig" />
          <SkeletonBaseForeground :width="80" v-bind="skeletonConfig" />
        </div>
      </div>
      <div class="desktop-skeleton__main">
        <div class="desktop-skeleton__main-left">
          <NewHeaderLogo />
          <div v-for="i in 4" :key="i" class="desktop-skeleton__main-left-item">
            <SkeletonBaseForeground :width="28" :height="28" :border-radius="8" />
            <SkeletonBaseForeground :width="40" :height="8" :border-radius="8" />
          </div>
        </div>
        <div class="desktop-skeleton__main-right">
          <SkeletonBaseForeground :width="140" v-bind="skeletonConfig" />
        </div>
      </div>
    </section>
    <NewHeaderDesktopContentController v-else />
  </SkeletonComponentLoader>
</template>

<script setup lang="ts">
import { useUserStore } from '~/store/user/user.store';
import { useNotificationsStore } from '../../store/notifications.store';

const userStore = useUserStore();
const { isLoaded: userSkeletonLoaded } = storeToRefs(userStore);

const notificationsStore = useNotificationsStore();
const { isLoaded: notificationsLoaded } = storeToRefs(notificationsStore);

const isLoaded = computed(() => userSkeletonLoaded.value && notificationsLoaded.value);

const skeletonConfig = {
  height: 40,
  borderRadius: 8,
};

const hidePart = ref(false);
const { y } = useWindowScroll();
const skeletonClass = computed(() => ({
  'desktop-skeleton--fixed': hidePart.value,
}));

watch(y, (newY, oldY) => {
  hidePart.value = newY > oldY;
});
</script>

<style lang="scss" scoped src="./DesktopController.scss"></style>
