<template>
  <NuxtLinkLocale :to="ROUTING.PAYMENT.MAIN" :class="['payment', rootClass]" @click="handleClick">
    <div class="payment__button">
      <SharedKitButton
        :color-preset="buttonColorPreset"
        :type-preset="TypePresets.RECTANGLE"
        :size-preset="SizePresets.S"
        :text="changeXl(false, true) ? '' : $t('header.payment.refill')"
        @click="handleButtonAmplitude"
      >
        <template v-if="changeXl(false, true)">
          <SvgoHeaderPaymentPlus v-if="promo" />
          <SvgoHeaderTabRefill v-else />
        </template>
      </SharedKitButton>
    </div>
    <div v-if="promo" class="payment__promo">
      <div class="payment__promo-percent">+{{ promo.percent }} <SvgoHeaderPaymentPercent /></div>
      <span class="payment__promo-text">{{ $t('header.payment.toAdd') }}</span>
    </div>
    <div v-else-if="changeXl(false, true)" class="payment__text">
      {{ $t('header.payment.enterPromo') }}
    </div>
    <SvgoHeaderPaymentPercent v-else class="payment__icon" />
  </NuxtLinkLocale>
</template>

<script setup lang="ts">
import type { IPaymentProps } from './Payment.types';
import { ColorPresets, TypePresets, SizePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { HeaderEvents } from '~/repository/amplitude/events/header';
import { PaymentEvents } from '~/repository/amplitude/events/payment';
import { EPaymentOpenedButtonPlaces } from '~/repository/amplitude/types/payment.types';

const changeXl = GlobalUtils.Media.changeByMedia('xl');

const { theme } = useProjectSettings();

const props = defineProps<IPaymentProps>();
const { promo } = toRefs(props);

const rootClass = computed(() => ({
  'payment--blogger': props.promo?.isBlogger,
  'payment--promo': props.promo && !props.promo?.isBlogger,
}));

const buttonColorPreset = computed(() => (theme === 'gray' ? ColorPresets.PRIMARY : ColorPresets.ACCENT));

const handleClick = () => {
  HeaderEvents.promoClicked();
};

const handleButtonAmplitude = () => {
  PaymentEvents.paymentOpened({ buttonPlace: EPaymentOpenedButtonPlaces.PROMO_POPUP });
};
</script>

<style scoped lang="scss" src="./Payment.scss"></style>
