<template>
  <div :class="['base-label', rootClass]">
    <slot>
      {{ text }}
    </slot>
  </div>
</template>

<script setup lang="ts">
import type { IBaseLabelProps } from './BaseLabel.types';

const props = defineProps<IBaseLabelProps>();
const { size } = toRefs(props);

const rootClass = computed(() => `base-label--${size.value}`);
</script>

<style lang="scss" scoped src="./BaseLabel.scss"></style>
