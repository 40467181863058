<template>
  <div class="desktop-inventory">
    <div v-if="!count" class="desktop-inventory__empty">
      <UiImage src="/static/shared/no-items.png" class="desktop-inventory__empty-image" />
      <span class="desktop-inventory__empty-text">{{ $t('header.inventory.empty') }}</span>
    </div>
    <div v-else class="desktop-inventory__content">
      <h3 class="desktop-inventory__content-title">
        {{ $t('header.inventory.title') }}
        <BaseNotification class="desktop-inventory__content-title--counter" size="m" :value="count" type="black" />
      </h3>
      <div class="desktop-inventory__content-buttons">
        <SharedKitButton
          :color-preset="ColorPresets.BURGER"
          :type-preset="TypePresets.RECTANGLE"
          :size-preset="SizePresets.M"
          :text="$t('header.inventory.openButton')"
          @click="$emit('openPopup')"
        />
        <SharedKitButton
          :color-preset="buttonColorPreset"
          :type-preset="TypePresets.RECTANGLE"
          :size-preset="SizePresets.M"
          :text="$t('header.inventory.sellButton', { sum: sum })"
          @click="$emit('sellAll')"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IDesktopInventoryProps, IDesktopInventoryEmits } from './DesktopInventory.types';
import { ColorPresets, SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';

const { theme } = useProjectSettings();

defineProps<IDesktopInventoryProps>();
defineEmits<IDesktopInventoryEmits>();

const buttonColorPreset = computed(() => (theme === 'gray' ? ColorPresets.PRIMARY : ColorPresets.ACCENT));
</script>

<style scoped lang="scss" src="./DesktopInventory.scss"></style>
