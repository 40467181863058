<template>
  <SkeletonComponentLoader>
    <section v-if="!isLoaded" class="mobile-skeleton">
      <div class="mobile-skeleton__top">
        <NewHeaderLogo />
        <SkeletonBaseForeground class="mobile-skeleton__top-button" v-bind="topButtonBlock" />
      </div>
      <div class="mobile-skeleton__bottom">
        <SkeletonBaseForeground
          v-for="i in 5"
          :key="i"
          class="mobile-skeleton__bottom-item"
          :width="32"
          :height="32"
          :border-radius="8"
        />
      </div>
    </section>
    <div v-else class="mobile-skeleton__content">
      <NewHeaderMobileTopController />
      <NewHeaderMobileSideController />
      <NewHeaderMobileBottomController />
    </div>
  </SkeletonComponentLoader>
</template>

<script setup lang="ts">
import { useUserStore } from '~/store/user/user.store';
import { useNotificationsStore } from '../../store/notifications.store';

const changeMd = GlobalUtils.Media.changeByMedia('md');

const userStore = useUserStore();
const { isLoaded: userSkeletonLoaded } = storeToRefs(userStore);

const notificationsStore = useNotificationsStore();
const { isLoaded: notificationsLoaded } = storeToRefs(notificationsStore);

const isLoaded = computed(() => userSkeletonLoaded.value && notificationsLoaded.value);

const topButtonBlock = computed(() =>
  changeMd(
    {
      width: 140,
      height: 40,
      borderRadius: 8,
    },
    {
      width: 112,
      height: 32,
      borderRadius: 4,
    },
  ),
);
</script>

<style lang="scss" scoped src="./MobileController.scss"></style>
