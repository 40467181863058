import type { IHeaderStoreApiData, IHeaderStoreApiResponse } from '~/store/header/header.types';

import { useUserStore } from '~/store/user/user.store';

export const useNotificationsStore = defineStore('header/notifications', () => {
  const { $api } = useNuxtApp();

  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);

  const isLoaded = ref(false);

  const sharedData = ref<IHeaderStoreApiData>();

  const getData = async () => {
    if (!user.value) {
      isLoaded.value = true;
      return;
    }

    try {
      const { data, status } = (await $api.user.getUserOffers(user.value.userId)) as IHeaderStoreApiResponse;
      if (!status) return;

      sharedData.value = data;
    } catch {
    } finally {
      isLoaded.value = true;
    }
  };

  return { isLoaded, sharedData, getData };
});
