<template>
  <div :class="['base-avatar', rootClass]" :style="rootStyles">
    <div class="base-avatar__content">
      <UiImage class="base-avatar__content-image" :src="image" />
      <div class="base-avatar__content-level-up">{{ level }}</div>
    </div>
    <BaseLabel v-if="size !== EBaseAvatarSizes.XXS" class="base-avatar__label" :size="labelSize" :text="`${level}`" />
  </div>
</template>

<script setup lang="ts">
import { EBaseAvatarSizes, type IBaseAvatarProps } from './BaseAvatar.types';

const props = defineProps<IBaseAvatarProps>();
const { level, progress, size } = toRefs(props);

const SIZES: Record<EBaseAvatarSizes, 'xs' | 's' | 'm'> = {
  [EBaseAvatarSizes.XXS]: 'xs',
  [EBaseAvatarSizes.XS]: 'xs',
  [EBaseAvatarSizes.S]: 'xs',
  [EBaseAvatarSizes.M]: 's',
  [EBaseAvatarSizes.L]: 's',
  [EBaseAvatarSizes.XL]: 'm',
  [EBaseAvatarSizes.XXL]: 'm',
};

const LEVEL_UP_ANIMATION_TIME = 250;

const isLevelUp = ref(false);
const smoothProgress = ref(0);

const rootClass = computed(() => ({
  [`base-avatar--${size.value}`]: true,
  'base-avatar--level-up': isLevelUp.value,
}));
const rootStyles = computed(() => ({
  '--level-color': getColorVariableByLevel(level.value),
  '--progress': `${smoothProgress.value}%`,
}));

const labelSize = computed(() => SIZES[size.value]);

watch(level, () => {
  isLevelUp.value = true;

  setTimeout(() => {
    isLevelUp.value = false;
  }, LEVEL_UP_ANIMATION_TIME * 2);
});

watch(
  progress,
  (newProgress) => {
    if (newProgress === 0) {
      smoothProgress.value = 0;
      return;
    }

    const diff = newProgress - smoothProgress.value;
    const step = diff < 0 ? -1 : 1;

    const interval = setInterval(
      () => {
        smoothProgress.value += step;

        if (smoothProgress.value === newProgress) {
          clearInterval(interval);
        }
      },
      LEVEL_UP_ANIMATION_TIME / Math.abs(diff),
    );
  },
  {
    immediate: true,
  },
);
</script>

<style lang="scss" scoped src="./BaseAvatar.scss"></style>
