import MobileBonusIcon from '../components/MobileBonusIcon/MobileBonusIcon.vue';

export type THeaderMenuKeys =
  | 'daily'
  | 'secret'
  | 'bonuses'
  | 'contracts'
  | 'upgrades'
  | 'techies'
  | 'ninjaDefuse'
  | 'battles'
  | 'faq'
  | 'profile'
  | 'pf'
  | 'vk'
  | 'telegram'
  | 'discord'
  | 'twitter'
  | 'instagram'
  | 'home'
  | 'menu'
  | 'close';

export interface THeaderMenuData {
  key: THeaderMenuKeys;
  title: string;
  icon: unknown;
  link?: string;
  action?: 'open' | 'close';
  external?: boolean;
}

export const BOTTOM_TABS: THeaderMenuData[] = [
  { key: 'home', title: 'header.menuItems.home', icon: 'SvgoHeaderTabHome', link: '/' },
  { key: 'battles', title: 'header.menuItems.battles', icon: 'SvgoHeaderTabBattles', link: '/battles' },
  { key: 'upgrades', title: 'header.menuItems.upgrades', icon: 'SvgoHeaderTabUpgrades', link: '/upgrades' },
  { key: 'daily', title: 'header.menuItems.daily', icon: 'SvgoHeaderTabDaily', link: '/daily_cases' },
  { key: 'menu', title: 'header.menuItems.menu', icon: 'SvgoHeaderTabMenu', action: 'open' },
  { key: 'close', title: 'header.menuItems.close', icon: 'SvgoHeaderTabClose', action: 'close' },
];

export const SIDE_ACTIVITIES: THeaderMenuData[] = [
  { key: 'daily', title: 'header.sideMenu.daily', icon: 'SvgoHeaderTabDailyColored', link: '/daily_cases' },
  { key: 'secret', title: 'header.sideMenu.secret', icon: 'SvgoHeaderTabSecretColored', link: '/offersshop' },
  { key: 'bonuses', title: 'header.sideMenu.bonuses', icon: MobileBonusIcon, link: '/bonuspage', external: true },
];

export const SIDE_MENU_ITEMS: THeaderMenuData[] = [
  {
    key: 'contracts',
    title: 'header.sideMenu.contracts',
    icon: 'SvgoHeaderTabContracts',
    link: '/contract',
    external: true,
  },
  { key: 'upgrades', title: 'header.sideMenu.upgrades', icon: 'SvgoHeaderTabUpgrades', link: '/upgrades' },
  { key: 'techies', title: 'header.sideMenu.techies', icon: 'SvgoHeaderTabTechies', link: '/techies' },
  { key: 'ninjaDefuse', title: 'header.sideMenu.ninja', icon: 'SvgoHeaderTabTechies', link: '/ninja-defuse' },
  { key: 'battles', title: 'header.sideMenu.battles', icon: 'SvgoHeaderTabBattles', link: '/battles' },
];

export const SIDE_ADDITIONAL_MENU: THeaderMenuData[] = [
  { key: 'faq', title: 'header.sideMenu.faq', icon: 'SvgoHeaderTabFaq', link: '/faq' },
  { key: 'pf', title: 'header.sideMenu.pf', icon: 'SvgoHeaderTabPf', link: '/provably-fair' },
];

export const SIDE_SOCIALS: THeaderMenuData[] = [
  { key: 'vk', title: 'header.socials.vk', icon: 'SvgoHeaderSocialsVk' },
  { key: 'telegram', title: 'header.socials.telegram', icon: 'SvgoHeaderSocialsTelegram' },
  { key: 'discord', title: 'header.socials.discord', icon: 'SvgoHeaderSocialsDiscord' },
  { key: 'twitter', title: 'header.socials.twitter', icon: 'SvgoHeaderSocialsTwitter' },
  { key: 'instagram', title: 'header.socials.instagram', icon: 'SvgoHeaderSocialsInstagram' },
];
