<template>
  <NuxtLinkLocale :to="item.link" :class="['desktop-activity', rootClass]">
    <div class="desktop-activity__border"></div>
    <div class="desktop-activity__background"></div>
    <span class="desktop-activity__text">{{ $t(item.title) }}</span>
    <div class="desktop-activity__icon-container">
      <component :is="item.icon" class="desktop-activity__icon" />
    </div>
    <BaseLabel v-if="timer" class="desktop-activity__timer" size="s" :text="timer" />
    <BaseNotification v-if="counter && counter.value" class="desktop-activity__counter" size="s" v-bind="counter" />
  </NuxtLinkLocale>
</template>

<script setup lang="ts">
import type { IDesktopActivityProps } from './DesktopActivity.types';

const props = defineProps<IDesktopActivityProps>();
const { item } = toRefs(props);

const rootClass = computed(() => ({
  [`desktop-activity--${item.value.key}`]: true,
}));
</script>

<style scoped lang="scss" src="./DesktopActivity.scss"></style>
