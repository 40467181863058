<template>
  <section class="mobile-bottom">
    <NewHeaderMobileTabItem
      v-for="item in bottomLeftItems"
      :key="item.title"
      :title="$t(item.title)"
      :counter="getCounter(item.key)"
      @click="handleClick(item)"
    >
      <template #icon>
        <component :is="item.icon" />
      </template>
    </NewHeaderMobileTabItem>
    <NewHeaderMobileTabItem :title="$t(mainButton.title)" no-effects @click="handleMainClick(mainButton.action)">
      <template #icon>
        <div class="mobile-bottom__main-button">
          <SharedKitButton
            :color-preset="mainButton.color"
            :type-preset="TypePresets.ROUND"
            :size-preset="SizePresets.M"
          >
            <component :is="mainButton.icon" />
          </SharedKitButton>
        </div>
      </template>
    </NewHeaderMobileTabItem>
    <NewHeaderMobileTabItem
      v-for="item in bottomRightItems"
      :key="item.title"
      :title="$t(item.title)"
      :counter="getCounter(item.key)"
      @click="handleClick(item)"
    >
      <template #icon>
        <component :is="item.icon" />
      </template>
    </NewHeaderMobileTabItem>
  </section>
</template>

<script setup lang="ts">
import type { IBaseNotificationProps } from '~/components/BaseNotification/BaseNotification.types';
import { ColorPresets, SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import type { THeaderMenuData, THeaderMenuKeys } from '../../constants/menuItems.constants';
import { PaymentEvents } from '~/repository/amplitude/events/payment';
import { EPaymentOpenedButtonPlaces } from '~/repository/amplitude/types/payment.types';
import { AuthEvents } from '~/repository/amplitude/events/auth';
import { EAuthButtonPlaceClicked } from '~/repository/amplitude/types/auth.types';

import { useAuthStore } from '~/features/authentication/store/authorization';
import { useAuthPopupStore } from '~/features/general-auth/store/popup.store';

import { useMobileMenuStore } from '../../store/mobileMenu.store';
import { useLinksStore } from '../../store/links.store';
import { useBattlesNotificationsStore } from '../../store/battlesNotifications.store';
import { useDailyNotificationsStore } from '../../store/dailyNotifications.store';
import { useBonusesNotificationsStore } from '../../store/bonusesNotifications.store';

const { theme } = useProjectSettings();

const authStore = useAuthStore();
const { isAuth } = storeToRefs(authStore);

const authPopupStore = useAuthPopupStore();
const mobileMenuStore = useMobileMenuStore();

const battlesNotificationsStore = useBattlesNotificationsStore();
const { battles: battlesNotifications } = storeToRefs(battlesNotificationsStore);

const dailyNotificationsStore = useDailyNotificationsStore();
const { dailyNotifications } = storeToRefs(dailyNotificationsStore);

const bonusesNotificationsStore = useBonusesNotificationsStore();
const { bonuses: bonusesNotifications } = storeToRefs(bonusesNotificationsStore);

const linksStore = useLinksStore();
const { bottomLeftItems, bottomRightItems } = storeToRefs(linksStore);

const mainButton = computed(() => {
  const icon = isAuth.value ? 'SvgoHeaderTabRefill' : 'SvgoHeaderTabAuth';
  const title = `header.menuItems.${isAuth.value ? 'refill' : 'auth'}`;
  const action: 'auth' | 'refill' = isAuth.value ? 'refill' : 'auth';
  const color = theme === 'gray' ? ColorPresets.PRIMARY : isAuth.value ? ColorPresets.ACCENT : ColorPresets.PRIMARY;

  return { icon, title, action, color };
});

const getCounter = (key: THeaderMenuKeys): Omit<IBaseNotificationProps, 'size'> | undefined => {
  if (key === 'battles') return battlesNotifications.value;
  if (key === 'daily') return dailyNotifications.value;

  if (key === 'menu') {
    const hasBattles = +battlesNotifications.value.value! > 0 && battlesNotifications.value.type === 'red';
    const hasDaily = +dailyNotifications.value.value! > 0 && dailyNotifications.value.type === 'red';
    const hasBonuses = +bonusesNotifications.value.value! > 0 && bonusesNotifications.value.type === 'red';

    return {
      value: hasBattles || hasDaily || hasBonuses ? undefined : 0,
      type: 'red',
    };
  }

  return undefined;
};

const handleClick = (item: THeaderMenuData) => {
  if (item.link) return navigateTo(useLocaleRoute()(item.link));

  if (item.action === 'open') {
    mobileMenuStore.open();
  }
};

const handleMainClick = (action: 'auth' | 'refill') => {
  if (action === 'auth') {
    AuthEvents.entryClicked({ 'Button Place': EAuthButtonPlaceClicked.HAMBURGER_MENU, 'Url': location.href });
    return authPopupStore.open();
  }

  PaymentEvents.paymentOpened({
    buttonPlace: EPaymentOpenedButtonPlaces.HAMBURGER_MENU,
  });

  return navigateTo(useLocaleRoute()(ROUTING.PAYMENT.MAIN));
};
</script>

<style scoped lang="scss" src="./MobileBottomController.scss"></style>
