<template>
  <div v-if="isAuth" class="support" @click="handleClick">
    <Teleport v-if="changeXl(true, false)" to="body">
      <SvgoSupport class="support__icon" @click="handleClick" />
    </Teleport>
    <SvgoSupport v-else class="support__icon" />
    <span class="support__text">{{ $t('header.support') }}</span>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '~/features/authentication/store/authorization';

const changeXl = GlobalUtils.Media.changeByMedia('xl');

const authStore = useAuthStore();
const { isAuth } = storeToRefs(authStore);

const handleClick = () => {
  if (!window.jivo_api) return;
  window.jivo_api.open();
};
</script>

<style lang="scss" scoped src="./SupportController.scss"></style>
