export type THeaderGamesKeys = 'dota' | 'cs';

export const GAMES = {
  dota: {
    icon: 'SvgoHeaderGamesDota',
    title: 'Dota 2',
    color: '#ce3434',
    link: 'https://bit.ly/41ZxC4g',
  },
  cs: {
    icon: 'SvgoHeaderGamesCs',
    title: 'CS',
    color: '#db931c',
    link: 'https://bit.ly/3Mik8uo',
  },
};
