<template>
  <header>
    <NewHeaderDesktopController v-if="changeXl(true, false)" />
    <NewHeaderMobileController v-else />
  </header>
</template>

<script setup lang="ts">
import { useUserStore } from '~/store/user/user.store';
import { useNotificationsStore } from '../../store/notifications.store';
import { useAuthStore } from '~/features/authentication/store/authorization';
import { useQsInventory } from '~/features/quickSale/store/qs.inventory';
import { useDailyCasesStore } from '~/store/dailyCases/dailyCases.store';
import { useOffersStore } from '~/features/secret-shop/store/offers.store';
import { useBattlesListStore } from '~/features/battles/store/battlesList.store';
import { usePromoStore } from '~/features/payment/store/promo.store';
import { useInfoStore } from '~/features/promocode-popup/store/info.store';
import { SAVED_PROMO_KEY } from '~/features/payment/constants/keys.constants';

const changeXl = GlobalUtils.Media.changeByMedia('xl');

const authStore = useAuthStore();
const { isAuth } = storeToRefs(authStore);

const userStore = useUserStore();
const { user, isUserLoaded } = storeToRefs(userStore);

const battlesListStore = useBattlesListStore();
const dailyCasesStore = useDailyCasesStore();
const qsInventoryStore = useQsInventory();
const offersStore = useOffersStore();

const promoStore = usePromoStore();
const { promoInfo } = storeToRefs(promoStore);

const promocodeInfoStore = useInfoStore();
const { parsedPromo } = storeToRefs(promocodeInfoStore);

if (parsedPromo.value) {
  promoInfo.value.input = parsedPromo.value;
}

const savedPromo = usePersistedRef(SAVED_PROMO_KEY, '');

onMounted(() => {
  if (!savedPromo.value || parsedPromo.value) return;
  promoInfo.value.input = savedPromo.value;
});

const notificationsStore = useNotificationsStore();

watch(
  isUserLoaded,
  (newIsUserLoaded) => {
    if (isAuth.value && !newIsUserLoaded) return;
    battlesListStore.fetchAllBattles(true);
    dailyCasesStore.getTimerInfo();
    offersStore.getOffers();
    notificationsStore.getData();
  },
  { immediate: true },
);

watch(
  () => user.value?.finance.totalAmountItems,
  (value) => {
    if (!value) return;
    qsInventoryStore.fetchInventory({ noUpdateInventory: true });
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
header {
  display: contents;
}
</style>
