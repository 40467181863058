import type { IBaseNotificationProps } from '~/components/BaseNotification/BaseNotification.types';

import { useOffersStore } from '~/features/secret-shop/store/offers.store';
import { useUserStore } from '~/store/user/user.store';

export const useSecretNotificationsStore = defineStore('header/secret-notifications', () => {
  const userStore = useUserStore();
  const { userId } = storeToRefs(userStore);

  const offersStore = useOffersStore();
  const { offers } = storeToRefs(offersStore);

  const viewed = usePersistedRef<number[]>(`secret-notifications-viewed-${userId.value}`, []);
  const items = ref<number[]>([]);

  const secret = ref<Omit<IBaseNotificationProps, 'size'>>({
    value: 0,
    type: 'red',
  });

  const update = (data: number[]) => {
    items.value = data;
    const notViewedOffers = data.filter((item) => !viewed.value.includes(item)) || [];

    secret.value.value = notViewedOffers.length;
  };

  const view = () => {
    const uncheckedKeys = items.value.filter((item) => !viewed.value.includes(item));
    viewed.value.push(...uncheckedKeys);

    secret.value.value = 0;
  };

  watch(
    offers,
    (newData) => {
      update([...newData.personal.keys(), ...newData.event.keys(), ...newData.limited.keys()]);
    },
    { immediate: true, deep: true },
  );

  onMounted(() => {
    if (!items.value.length) return;
    update(items.value);
  });

  return { secret, update, view };
});
