<template>
  <div class="desktop-profile">
    <div class="desktop-profile__base">
      <NuxtLinkLocale :to="ROUTING.PROFILE.USER_PAGE_ME" class="desktop-profile__title">{{ name }}</NuxtLinkLocale>
      <div class="desktop-profile__user">
        <NuxtLinkLocale :to="ROUTING.PROFILE.USER_PAGE_ME">
          <UiImage :src="avatar" class="desktop-profile__user-avatar" />
        </NuxtLinkLocale>
        <div class="desktop-profile__user-level">
          <span class="desktop-profile__user-level-exp">
            <span class="desktop-profile__user-level-exp--value">{{ spaceInThousand(levelInfo.expToNext) }} XP</span>
            {{ $t('header.desktopProfile.toNextLevel') }}
          </span>
          <div class="desktop-profile__user-level-progress" :style="progressBarStyles">
            <BaseLabel class="desktop-profile__user-level-progress-current" size="m" :text="levelFormatted.current" />
            <div class="desktop-profile__user-level-progress-bar">{{ levelFormatted.progress }}</div>
            <BaseLabel
              v-if="levelFormatted.next"
              class="desktop-profile__user-level-progress-next"
              size="m"
              :text="levelFormatted.next"
            />
          </div>
        </div>
      </div>
      <span class="desktop-profile__instruction" @click="$emit('showInstruction')">
        {{ $t('header.desktopProfile.instruction') }}
      </span>
    </div>
    <div class="desktop-profile__menu">
      <NewHeaderSideItem
        :title="$t('header.sideMenu.profile')"
        @click="handleLink('profile', ROUTING.PROFILE.USER_PAGE_ME.path)"
      >
        <template #icon>
          <SvgoHeaderTabProfile class="desktop-profile__menu-icon" />
        </template>
      </NewHeaderSideItem>
      <NewHeaderSideItem :title="$t('header.sideMenu.faq')" @click="handleLink('faq', ROUTING.FAQ.MAIN.path)">
        <template #icon>
          <SvgoHeaderTabFaq class="desktop-profile__menu-icon" />
        </template>
      </NewHeaderSideItem>
    </div>
    <div class="desktop-profile__socials">
      <component
        :is="item.icon"
        v-for="item in socials"
        :key="item.key"
        @click="handleSocialClick(item.key, item.link)"
      />
    </div>
    <div class="desktop-profile__logout" @click="$emit('logout')">
      <SvgoHeaderTabLogout />
      <span>{{ $t('header.sideMenu.logout') }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IDesktopProfileProps, IDesktopProfileEmits } from './DesktopProfile.types';
import { HeaderEvents } from '~/repository/amplitude/events/header';
import { useLinksStore } from '../../store/links.store';
import { EHeaderSocialLinkClickedPlaces } from '~/repository/amplitude/types/header.types';
import type { THeaderMenuKeys } from '../../constants/menuItems.constants';

const linksStore = useLinksStore();
const { socials } = storeToRefs(linksStore);

const props = defineProps<IDesktopProfileProps>();
defineEmits<IDesktopProfileEmits>();

const levelFormatted = computed(() => ({
  current: `${props.levelInfo.current}`,
  progress: `${props.levelInfo.progress}%`,
  next: `${props.levelInfo.current + 1}`,
}));

const progressBarStyles = computed(() => ({
  '--current-level': getColorVariableByLevel(+levelFormatted.value.current),
  '--filled-width': levelFormatted.value.progress,
  '--next-level': getColorVariableByLevel(+levelFormatted.value.next),
}));

const handleLink = (key: THeaderMenuKeys, link?: string) => {
  if (!link) return;

  if (key === 'faq') {
    HeaderEvents.faqClicked();
  }

  return navigateTo(useLocaleRoute()(link));
};

const handleSocialClick = (key: string, link?: string) => {
  if (!link) return;

  HeaderEvents.socialLinkClicked({ 'Link Place': EHeaderSocialLinkClickedPlaces.HEADER, 'Social Type': key });
  return navigateTo(link, { external: true, open: { target: '_blank' } });
};
</script>

<style scoped lang="scss" src="./DesktopProfile.scss"></style>
