export enum EHeaderEvents {
  SECRET_SHOP_CLICKED = 'Navigation - Secret Shop Clicked',
  QUICK_SELL_POPUP_OPENED = 'Quick Sell - Pop-up Opened',
  LOGOUT_CLICKED = 'Profile - Log Out Clicked',
  PROMO_CLICKED = 'Navigation - Promo Clicked',
  SOCIAL_LINK_CLICKED = 'Social - Link Clicked',
  FAQ_CLICKED = 'Navigation - FAQ Clicked',
}

export enum EHeaderQuickSellPopupOpenedPlaces {
  HEADER = 'Header',
  HAMBURGER_MENU = 'Hamburger Menu',
}

export enum EHeaderSocialLinkClickedPlaces {
  HEADER = 'Header',
  HAMBURGER_MENU = 'Hamburger Menu',
  FOOTER = 'Footer',
}

export interface IHeaderQuickSellPopupOpenedEvent {
  'Button Place': EHeaderQuickSellPopupOpenedPlaces;
}

export interface IHeaderSocialLinkClickedEvent {
  'Link Place': EHeaderSocialLinkClickedPlaces;
  'Social Type': string;
}
