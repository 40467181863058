<template>
  <div :class="['desktop-content', rootClass]">
    <HowItWorksPopup v-model="showHowItWorksPopup" :timer="timeUntilEnd" />
    <div class="desktop-content__top">
      <div class="desktop-content__top-left">
        <BaseTooltip position="bottom">
          <template #default>
            <span class="desktop-content__top-left-switch">
              {{ $t('header.switchGame', { game: otherGame.title }) }}
            </span>
          </template>
          <template #preview-icon>
            <NewHeaderDesktopIconButton :color="otherGame.color" :shadow="otherGame.color" @click="handleGameSwitch()">
              <component :is="otherGame.icon" />
            </NewHeaderDesktopIconButton>
          </template>
        </BaseTooltip>
        <NewHeaderDesktopActivity
          v-for="activity in topLeftActivities"
          :key="activity.key"
          :item="activity"
          :counter="getCounterData(activity.key)"
          :timer="getTimerData(activity.key)"
          @click="handleActivityAmplitude(activity.key)"
        />
      </div>
      <div class="desktop-content__top-right">
        <NewHeaderDesktopBonus
          v-if="bonusActivity"
          :link="bonusActivity.link!"
          :text="$t(bonusActivity.title)"
          :external="bonusActivity.external"
          :counter="getCounterData(bonusActivity.key)"
        />
        <LocalizationLocaleSwitcherController v-if="currentLocale && locales.length > 1" />
        <NewHeaderDesktopIconButton
          v-if="isAuth"
          color="var(--neutral-white)"
          shadow="var(--neutral-white)"
          @click="toggleMute()"
        >
          <Transition name="fade" mode="out-in">
            <SvgoHeaderSoundOn v-if="volume" />
            <SvgoHeaderSoundOff v-else />
          </Transition>
        </NewHeaderDesktopIconButton>
      </div>
    </div>
    <div class="desktop-content__main">
      <div class="desktop-content__main-left">
        <NewHeaderLogo />
        <NewHeaderDesktopGameMode
          v-for="item in desktopGameModes"
          :key="item.key"
          :item="item"
          :counter="getCounterData(item.key)"
        />
      </div>
      <div class="desktop-content__main-right">
        <div v-if="user" class="desktop-content__main-right-content">
          <NewHeaderDesktopBalance
            :money="Number(user.finance.balance)"
            :coins="Number(user.finance.coinBalance)"
            :inventory="Number(user.finance.totalAmountItems)"
            :event="{
              color: coinColor,
              icon: coinIcon,
            }"
          >
            <template #inventory-tooltip>
              <NewHeaderDesktopInventory
                :count="totalItems"
                :sum="getCurrencyWithSpaces(totalPrice)"
                @open-popup="handleInventoryOpenPopup()"
                @sell-all="qsInventoryStore.sellAllInventory()"
              />
            </template>
          </NewHeaderDesktopBalance>
          <BaseTooltip position="bottom">
            <template #default>
              <span class="desktop-content__main-right-payment">
                {{ $t(paymentTooltipText) }}
              </span>
            </template>
            <template #preview-icon>
              <NewHeaderPayment :promo="promoData" />
            </template>
          </BaseTooltip>
          <BaseTooltip class="desktop-content__main-right-profile" position="bottom" hold-tooltip-on-leave>
            <template #default>
              <NewHeaderDesktopProfile
                :name="user.nickname"
                :avatar="user.avatar"
                :level-info="{
                  current: Number(user.userLevel),
                  progress: Number(user.percentToNextLevel),
                  expToNext: Number(user.expToNextLevel),
                }"
                @show-instruction="showHowItWorksPopup = true"
                @logout="handleLogout()"
              />
            </template>
            <template #preview-icon>
              <BaseAvatar
                :size="EBaseAvatarSizes.S"
                :image="user.avatar"
                :level="Number(user.userLevel)"
                :progress="Number(user.percentToNextLevel)"
              />
            </template>
          </BaseTooltip>
        </div>
        <div v-else class="desktop-content__main-right-auth">
          <SharedKitButton
            :color-preset="ColorPresets.PRIMARY"
            :type-preset="TypePresets.RECTANGLE"
            :size-preset="SizePresets.M"
            :text="$t('header.auth')"
            @click="handleStartAuth()"
          />
        </div>
      </div>
    </div>
    <NewHeaderSupportController />
  </div>
</template>

<script setup lang="ts">
import { GAMES } from '../../constants/games.constants';
import { ColorPresets, SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import type { THeaderMenuData, THeaderMenuKeys } from '../../constants/menuItems.constants';
import { EBaseAvatarSizes } from '~/components/BaseAvatar/BaseAvatar.types';
import { HeaderEvents } from '~/repository/amplitude/events/header';
import { AuthEvents } from '~/repository/amplitude/events/auth';
import { EHeaderQuickSellPopupOpenedPlaces } from '~/repository/amplitude/types/header.types';
import { EAuthButtonPlaceClicked } from '~/repository/amplitude/types/auth.types';

import { useAuthStore } from '~/features/authentication/store/authorization';
import { useAuthPopupStore } from '~/features/general-auth/store/popup.store';
import { useLocalizationStore } from '~/features/localization/store/localization.store';
import { useEventStore } from '~/store/event/event.store';
import { useUserStore } from '~/store/user/user.store';
import { useQsInventory } from '~/features/quickSale/store/qs.inventory';
import { usePromoStore } from '~/features/payment/store/promo.store';
import { useQsPopupStore } from '~/features/quickSale/store/qsPopup.store';
import { useDailyCasesStore } from '~/store/dailyCases/dailyCases.store';

import { useLinksStore } from '../../store/links.store';
import { useBattlesNotificationsStore } from '../../store/battlesNotifications.store';
import { useBonusesNotificationsStore } from '../../store/bonusesNotifications.store';
import { useDailyNotificationsStore } from '../../store/dailyNotifications.store';
import { useSecretNotificationsStore } from '../../store/secretNotifications.store';

const {
  game,
  header: { otherGamesLinks },
} = useProjectSettings();

const authStore = useAuthStore();
const { isAuth } = storeToRefs(authStore);

const authPopupStore = useAuthPopupStore();

const eventStore = useEventStore();
const { coinColor, coinIcon } = storeToRefs(eventStore);

const dailyCasesStore = useDailyCasesStore();
const { secondsUntilEnd } = storeToRefs(dailyCasesStore);

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const qsInventoryStore = useQsInventory();
const { totalItems, totalPrice } = storeToRefs(qsInventoryStore);

const promoStore = usePromoStore();
const { currentPromo, commonPromo, bloggerPromo } = storeToRefs(promoStore);

const qsPopupStore = useQsPopupStore();

const linksStore = useLinksStore();
const { activities, desktopGameModes } = storeToRefs(linksStore);

const localizationStore = useLocalizationStore();
const { locales, currentLocale } = storeToRefs(localizationStore);

const battlesNotificationsStore = useBattlesNotificationsStore();
const { battles: battlesNotifications } = storeToRefs(battlesNotificationsStore);

const secretNotificationsStore = useSecretNotificationsStore();
const { secret: secretNotifications } = storeToRefs(secretNotificationsStore);

const bonusesNotificationsStore = useBonusesNotificationsStore();
const { bonuses: bonusesNotifications } = storeToRefs(bonusesNotificationsStore);

const dailyNotificationsStore = useDailyNotificationsStore();
const { dailyNotifications, dailyTimer } = storeToRefs(dailyNotificationsStore);

const { volume, mute, unmute } = useVolume();

const toggleMute = () => {
  if (volume.value) mute();
  else unmute();
};

const hidePart = ref(false);
const { y } = useWindowScroll();
const rootClass = computed(() => ({
  'desktop-content--fixed': hidePart.value,
}));

watch(y, (newY, oldY) => {
  hidePart.value = newY > oldY;
});

const showHowItWorksPopup = ref(false);
const timeUntilEnd = computed(() => Date.now() + secondsUntilEnd.value * 1000);

const otherGame = computed(() =>
  game === 'dota' ? { ...GAMES.cs, link: otherGamesLinks.cs } : { ...GAMES.dota, link: otherGamesLinks.dota },
);

const topLeftActivities = computed(() => {
  const secretShop = activities.value.find((activity) => activity.key === 'secret');
  const dailyCases = activities.value.find((activity) => activity.key === 'daily');

  const result: THeaderMenuData[] = [];
  if (secretShop) result.push(secretShop);
  if (dailyCases) result.push(dailyCases);

  return result;
});

const promoData = computed(() => {
  if (!currentPromo.value) return;

  if (!bloggerPromo.value) return { isBlogger: false, percent: commonPromo.value };

  return {
    isBlogger: true,
    percent: bloggerPromo.value[bloggerPromo.value.length - 1].percent,
  };
});

const paymentTooltipText = computed(() => `header.payment.${promoData.value ? 'promoEntered' : 'enterPromo'}`);

const bonusActivity = computed(() => activities.value.find((activity) => activity.key === 'bonuses'));

const handleGameSwitch = () => navigateTo(otherGame.value.link, { external: true, open: { target: '_blank' } });

const handleActivityAmplitude = (key: THeaderMenuKeys) => {
  if (key === 'secret') {
    HeaderEvents.secretShopClicked();
    secretNotificationsStore.view();
  }
};

const handleInventoryOpenPopup = () => {
  HeaderEvents.quickSellPopupOpened({ 'Button Place': EHeaderQuickSellPopupOpenedPlaces.HEADER });
  qsPopupStore.open();
};

const handleLogout = () => {
  HeaderEvents.logoutClicked();
  authStore.logout();
};

const handleStartAuth = () => {
  AuthEvents.entryClicked({ 'Button Place': EAuthButtonPlaceClicked.HEADER, 'Url': location.href });
  authPopupStore.open();
};

const getCounterData = (key: THeaderMenuKeys) => {
  if (key === 'secret') return secretNotifications.value;
  if (key === 'daily') return dailyNotifications.value;
  if (key === 'bonuses') return bonusesNotifications.value;
  if (key === 'battles') return battlesNotifications.value;

  return undefined;
};

const getTimerData = (key: THeaderMenuKeys) => {
  if (key === 'daily') return dailyTimer.value;

  return undefined;
};
</script>

<style scoped lang="scss" src="./DesktopContentController.scss"></style>
