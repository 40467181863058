import {
  EHeaderEvents,
  type IHeaderQuickSellPopupOpenedEvent,
  type IHeaderSocialLinkClickedEvent,
} from '../types/header.types';

export class HeaderEvents {
  static secretShopClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EHeaderEvents.SECRET_SHOP_CLICKED,
    });
  }

  static quickSellPopupOpened(event: IHeaderQuickSellPopupOpenedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EHeaderEvents.QUICK_SELL_POPUP_OPENED,
      eventProperties: event,
    });
  }

  static logoutClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EHeaderEvents.LOGOUT_CLICKED,
    });
  }

  static promoClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EHeaderEvents.PROMO_CLICKED,
    });
  }

  static socialLinkClicked(event: IHeaderSocialLinkClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EHeaderEvents.SOCIAL_LINK_CLICKED,
      eventProperties: event,
    });
  }

  static faqClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EHeaderEvents.FAQ_CLICKED,
    });
  }
}
