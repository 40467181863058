<template>
  <div class="mobile-games">
    <div class="mobile-games__content" @click="isOpen = !isOpen">
      <component :is="currentGame.icon" class="mobile-games__content-icon" :style="{ color: currentGame.color }" />
      <SvgoNavigationDown class="mobile-games__content-arrow" />
    </div>
    <Transition name="fade" mode="out-in" @after-enter="handleClickOutside">
      <div v-if="isOpen" ref="optionsRef" class="mobile-games__options">
        <div
          v-for="(item, key) in GAMES"
          :key="key"
          class="mobile-games__options-item"
          @click="handleOptionSelect(key)"
        >
          <component :is="item.icon" class="mobile-games__options-item-icon" :style="{ color: item.color }" />
          <SvgoCheckmarkIcon v-if="game === key" class="mobile-games__options-item-checkmark" />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { GAMES } from '~/features/header/constants/games.constants';

const {
  game,
  header: { otherGamesLinks },
} = useProjectSettings();
const currentGame = computed(() => GAMES[game]);

const handleOptionSelect = (key: keyof typeof GAMES) => {
  if (game === key) return;
  if (!otherGamesLinks[key]) return;

  return navigateTo(otherGamesLinks[key], { external: true, open: { target: '_blank' } });
};

const isOpen = ref(false);
const optionsRef = ref<HTMLElement>();
const removeClickOutside = ref<() => void>();

const close = () => {
  isOpen.value = false;
};

const handleClickOutside = () => {
  if (!optionsRef.value) return;
  const { remove } = useClickOutside(optionsRef.value, close);
  removeClickOutside.value = remove;
};

watch(isOpen, (newValue) => {
  if (newValue || !removeClickOutside.value) return;
  removeClickOutside.value();
});
</script>

<style scoped lang="scss" src="./MobileGames.scss"></style>
