<template>
  <Teleport to="#teleports">
    <Transition name="open-side-menu" mode="out-in">
      <section v-if="isOpened" ref="menuRef" class="mobile-side">
        <div class="mobile-side__top">
          <div v-if="!user" class="mobile-side__top-auth">
            <div class="mobile-side__top-auth-button">
              <SharedKitButton
                :text="$t('header.auth')"
                :color-preset="ColorPresets.PRIMARY"
                :type-preset="TypePresets.RECTANGLE"
                :size-preset="SizePresets.S"
                @click="handleStartAuth()"
              />
            </div>
            <NewHeaderMobileGames />
          </div>
          <div v-else class="mobile-side__top-user">
            <NewHeaderMobileBalance
              class="mobile-side__top-user-balance"
              :money="Number(user.finance.balance)"
              :coins="Number(user.finance.coinBalance)"
              :event="{ icon: coinIcon, color: coinColor }"
            />
            <div class="mobile-side__top-user-controls">
              <div class="mobile-side__top-user-sound" @click="toggleMute()">
                <Transition name="fade" mode="out-in">
                  <SvgoHeaderSoundOn v-if="volume" />
                  <SvgoHeaderSoundOff v-else />
                </Transition>
              </div>
              <NewHeaderMobileGames />
            </div>
          </div>
        </div>
        <div class="mobile-side__content">
          <div class="mobile-side__content-block">
            <template v-if="user">
              <NewHeaderSideItem
                :title="$t('header.sideMenu.profile')"
                @click="handleLink('profile', ROUTING.PROFILE.USER_PAGE_ME.path)"
              >
                <template #icon>
                  <BaseAvatar
                    v-if="user"
                    :size="EBaseAvatarSizes.XXS"
                    :image="user.avatar"
                    :level="Number(user.userLevel)"
                    :progress="Number(user.percentToNextLevel)"
                  />
                </template>
                <template #main-content>
                  <BaseLabel
                    :style="levelLabelColor"
                    size="s"
                    :text="$t('header.profileLevel', { n: user.userLevel })"
                  />
                </template>
              </NewHeaderSideItem>
              <NewHeaderSideItem :title="$t('header.sideMenu.inventory')" @click="handleInventoryClick()">
                <template #icon>
                  <SvgoHeaderBalanceInventory />
                </template>
                <template #right-content>
                  <BaseLabel
                    v-if="Number(user.finance.totalAmountItems)"
                    class="mobile-side__content-label--inventory"
                    size="m"
                  >
                    <BaseCurrency>{{ getCurrencyWithSpaces(user.finance.totalAmountItems) }}</BaseCurrency>
                  </BaseLabel>
                </template>
              </NewHeaderSideItem>
            </template>
            <NewHeaderSideItem
              v-for="(item, key) in activities"
              :key="key"
              :title="$t(item.title)"
              :counter="getCounter(item.key)"
              @click="handleLink(item.key, item.link, item.external)"
            >
              <template #icon>
                <component :is="item.icon" />
              </template>
              <template v-if="getLabel(item.key)" #right-content>
                <BaseLabel :class="`mobile-side__content-label--${item.key}`" :text="getLabel(item.key)" size="m" />
              </template>
            </NewHeaderSideItem>
          </div>
          <div class="mobile-side__content-divider"></div>
          <div class="mobile-side__content-block">
            <NewHeaderSideItem
              v-for="(item, key) in menuItems"
              :key="key"
              :title="$t(item.title)"
              :counter="getCounter(item.key)"
              @click="handleLink(item.key, item.link, item.external)"
            >
              <template #icon>
                <component :is="item.icon" />
              </template>
            </NewHeaderSideItem>
          </div>
          <div class="mobile-side__content-divider"></div>
          <div class="mobile-side__content-block">
            <NewHeaderSideItem
              v-for="(item, key) in additionalMenu"
              :key="key"
              :title="$t(item.title)"
              :counter="getCounter(item.key)"
              @click="handleLink(item.key, item.link, item.external)"
            >
              <template #icon>
                <component :is="item.icon" />
              </template>
            </NewHeaderSideItem>
            <NewHeaderSupportController />
          </div>
          <div class="mobile-side__content-socials">
            <LocalizationLocaleSwitcherController v-if="currentLocale && locales.length > 1" />
            <div v-else class="mobile-side__content-divider"></div>
            <div class="mobile-side__content-socials-items">
              <component
                :is="item.icon"
                v-for="item in socials"
                :key="item.key"
                @click="handleSocialClick(item.key, item.link)"
              />
            </div>
          </div>
          <div v-if="user" class="mobile-side__content-logout" @click="handleLogout">
            <SvgoHeaderTabLogout />
            <span>{{ $t('header.sideMenu.logout') }}</span>
          </div>
        </div>
        <div class="mobile-side__bottom">
          <div v-if="!user" class="mobile-side__bottom-auth">
            <SharedKitButton
              :text="$t('header.auth')"
              :color-preset="ColorPresets.PRIMARY"
              :type-preset="TypePresets.RECTANGLE"
              :size-preset="SizePresets.M"
              @click="handleStartAuth()"
            />
          </div>
          <div v-else class="mobile-side__bottom-user">
            <NewHeaderPayment :promo="promoData" @click="mobileMenuStore.close()" />
          </div>
          <div class="mobile-side__bottom-close">
            <NewHeaderMobileTabItem :title="$t('header.menuItems.close')" @click="mobileMenuStore.close()">
              <template #icon>
                <SvgoHeaderTabClose />
              </template>
            </NewHeaderMobileTabItem>
          </div>
        </div>
      </section>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import { EBaseAvatarSizes } from '~/components/BaseAvatar/BaseAvatar.types';
import { ColorPresets, SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import type { THeaderMenuKeys } from '../../constants/menuItems.constants';
import { HeaderEvents } from '~/repository/amplitude/events/header';
import { AuthEvents } from '~/repository/amplitude/events/auth';
import { EAuthButtonPlaceClicked } from '~/repository/amplitude/types/auth.types';

import { useAuthPopupStore } from '~/features/general-auth/store/popup.store';
import { useEventStore } from '~/store/event/event.store';
import { useUserStore } from '~/store/user/user.store';
import { useAuthStore } from '~/features/authentication/store/authorization';
import { useQsPopupStore } from '~/features/quickSale/store/qsPopup.store';
import { useLocalizationStore } from '~/features/localization/store/localization.store';

import { useMobileMenuStore } from '../../store/mobileMenu.store';
import { useLinksStore } from '../../store/links.store';
import { useBattlesNotificationsStore } from '../../store/battlesNotifications.store';
import { useSecretNotificationsStore } from '../../store/secretNotifications.store';
import { useBonusesNotificationsStore } from '../../store/bonusesNotifications.store';
import { useDailyNotificationsStore } from '../../store/dailyNotifications.store';
import {
  EHeaderQuickSellPopupOpenedPlaces,
  EHeaderSocialLinkClickedPlaces,
} from '~/repository/amplitude/types/header.types';
import { usePromoStore } from '~/features/payment/store/promo.store';

const eventStore = useEventStore();
const { coinColor, coinIcon } = storeToRefs(eventStore);

const localizationStore = useLocalizationStore();
const { locales, currentLocale } = storeToRefs(localizationStore);

const authStore = useAuthStore();
const authPopupStore = useAuthPopupStore();
const qsPopupStore = useQsPopupStore();

const battlesNotificationsStore = useBattlesNotificationsStore();
const { battles: battlesNotifications } = storeToRefs(battlesNotificationsStore);

const secretNotificationsStore = useSecretNotificationsStore();
const { secret: secretNotifications } = storeToRefs(secretNotificationsStore);

const bonusesNotificationsStore = useBonusesNotificationsStore();
const { bonuses: bonusesNotifications } = storeToRefs(bonusesNotificationsStore);

const dailyNotificationsStore = useDailyNotificationsStore();
const { dailyNotifications, dailyTimer } = storeToRefs(dailyNotificationsStore);

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const { volume, mute, unmute } = useVolume();

const toggleMute = () => {
  if (volume.value) mute();
  else unmute();
};

const promoStore = usePromoStore();
const { currentPromo, commonPromo, bloggerPromo } = storeToRefs(promoStore);

const mobileMenuStore = useMobileMenuStore();
const { isOpened } = storeToRefs(mobileMenuStore);

const linksStore = useLinksStore();
const { activities, menuItems, additionalMenu, socials } = storeToRefs(linksStore);

const menuRef = ref<HTMLElement>();

onClickOutside(menuRef, mobileMenuStore.close);

const promoData = computed(() => {
  if (!currentPromo.value) return;

  if (!bloggerPromo.value) return { isBlogger: false, percent: commonPromo.value };

  return {
    isBlogger: true,
    percent: bloggerPromo.value[bloggerPromo.value.length - 1].percent,
  };
});

const handleStartAuth = () => {
  AuthEvents.entryClicked({ 'Button Place': EAuthButtonPlaceClicked.HAMBURGER_MENU, 'Url': location.href });

  mobileMenuStore.close();
  authPopupStore.open();
};

const getCounter = (key: THeaderMenuKeys) => {
  if (key === 'battles') return battlesNotifications.value;
  if (key === 'secret') return secretNotifications.value;
  if (key === 'bonuses') return bonusesNotifications.value;
  if (key === 'daily') return dailyNotifications.value;

  return undefined;
};

const getLabel = (key: THeaderMenuKeys) => {
  if (key === 'daily') return dailyTimer.value;

  return undefined;
};

const handleLink = (key: THeaderMenuKeys, link?: string, external?: boolean) => {
  if (!link) return;
  mobileMenuStore.close();

  if (key === 'secret') {
    HeaderEvents.secretShopClicked();
    secretNotificationsStore.view();
  }

  if (key === 'faq') {
    HeaderEvents.faqClicked();
  }

  return navigateTo(useLocaleRoute()(link), { external });
};

const handleInventoryClick = () => {
  HeaderEvents.quickSellPopupOpened({ 'Button Place': EHeaderQuickSellPopupOpenedPlaces.HAMBURGER_MENU });

  mobileMenuStore.close();
  qsPopupStore.open();
};

const handleLogout = () => {
  HeaderEvents.logoutClicked();
  authStore.logout();
};

const handleSocialClick = (key: string, link?: string) => {
  if (!link) return;

  HeaderEvents.socialLinkClicked({ 'Link Place': EHeaderSocialLinkClickedPlaces.HAMBURGER_MENU, 'Social Type': key });

  return navigateTo(link, { external: true, open: { target: '_blank' } });
};

const levelLabelColor = computed(() => {
  if (!user.value) return;

  return {
    '--background': getColorVariableByLevel(Number(user.value.userLevel)),
    '--color': 'var(--neutral-white)',
  };
});
</script>

<style lang="scss">
.open-side-menu-enter-active,
.open-side-menu-leave-active {
  transition: transform var(--default-duration) ease-in-out;
}

.open-side-menu-enter-from,
.open-side-menu-leave-to {
  transform: translateX(280px);
}
</style>

<style scoped lang="scss" src="./MobileSideController.scss"></style>
