<template>
  <NuxtLinkLocale :to="item.link" :external="item.external" class="desktop-game-mode">
    <BaseNotification v-if="counter && counter.value" class="desktop-game-mode__counter" size="s" v-bind="counter" />
    <component :is="item.icon" class="desktop-game-mode__icon" />
    <span class="desktop-game-mode__text">{{ $t(item.title) }}</span>
    <div class="desktop-game-mode__shadow">
      <component :is="shadowIcon" class="desktop-game-mode__shadow-icon" />
    </div>
  </NuxtLinkLocale>
</template>

<script setup lang="ts">
import type { IDesktopGameModeProps } from './DesktopGameMode.types';

const props = defineProps<IDesktopGameModeProps>();
const { item } = toRefs(props);

const shadowIcon = computed(() => {
  const capitalizedKey = item.value.key.charAt(0).toUpperCase() + item.value.key.slice(1);
  return `SvgoHeader${capitalizedKey}Shadow`;
});
</script>

<style scoped lang="scss" src="./DesktopGameMode.scss"></style>
