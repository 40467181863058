<template>
  <div class="desktop-balance" :style="rootStyles">
    <div class="desktop-balance__line desktop-balance__line--inventory">
      <BaseTooltip key="inventory-tooltip" position="bottom" hold-tooltip-on-leave>
        <template #default>
          <slot name="inventory-tooltip" />
        </template>
        <template #preview-icon>
          <SvgoHeaderBalanceInventory class="desktop-balance__icon" />
          <BaseCurrency class="desktop-balance__amount">{{ getCurrencyWithSpaces(inventory) }}</BaseCurrency>
        </template>
      </BaseTooltip>
    </div>
    <div class="desktop-balance__divider"></div>
    <div class="desktop-balance__line desktop-balance__line--coins">
      <BaseTooltip key="coin-tooltip" position="bottom">
        <template #default>
          <div class="desktop-balance__coin-info">
            <h2 class="desktop-balance__coin-info-title">
              <UiImage v-if="event.icon" class="desktop-balance__coin-info-icon" :src="event.icon" />
              {{ $t('header.coinInfo.title') }}
            </h2>
            <p class="desktop-balance__coin-info-text">{{ $t('header.coinInfo.text1') }}</p>
            <p class="desktop-balance__coin-info-text">{{ $t('header.coinInfo.text2') }}</p>
          </div>
        </template>
        <template #preview-icon>
          <UiImage v-if="event.icon" class="desktop-balance__icon" :src="event.icon" />
          <span class="desktop-balance__amount">{{ spaceInThousand(coins) }}</span>
        </template>
      </BaseTooltip>
    </div>
    <div class="desktop-balance__divider"></div>
    <div class="desktop-balance__line desktop-balance__line--money" @click="handleMoneyClick">
      <SvgoHeaderBalanceMoney class="desktop-balance__icon" />
      <BaseCurrency class="desktop-balance__amount">{{ getCurrencyWithSpaces(money) }}</BaseCurrency>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IDesktopBalanceProps } from './DesktopBalance.types';
import { PaymentEvents } from '~/repository/amplitude/events/payment';
import { EPaymentOpenedButtonPlaces } from '~/repository/amplitude/types/payment.types';

const props = defineProps<IDesktopBalanceProps>();

const rootStyles = computed(() => ({
  '--event-color': props.event.color,
}));

const handleMoneyClick = () => {
  PaymentEvents.paymentOpened({
    buttonPlace: EPaymentOpenedButtonPlaces.HEADER,
  });

  return navigateTo(useLocaleRoute()(ROUTING.PAYMENT.MAIN));
};
</script>

<style scoped lang="scss" src="./DesktopBalance.scss"></style>
