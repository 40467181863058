<template>
  <div :class="['base-notification', rootClass]">
    <span v-if="value">{{ value }}</span>
  </div>
</template>

<script setup lang="ts">
import type { IBaseNotificationProps } from './BaseNotification.types';

const props = defineProps<IBaseNotificationProps>();
const { value, size, type } = toRefs(props);

const rootClass = computed(() => ({
  [`base-notification--${size.value}`]: true,
  [`base-notification--${type.value}`]: true,
  'base-notification--no-value': !value.value,
}));
</script>

<style lang="scss" scoped src="./BaseNotification.scss"></style>
