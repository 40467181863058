<template>
  <section v-if="currentLocale && locales.length > 1" class="locale-switcher-controller">
    <div class="locale-switcher-controller__selected" @click="isListOpened = !isListOpened">
      <UiImage v-if="currentLocale.icon" :src="currentLocale.icon" class="locale-switcher-controller__selected--icon" />
      <span class="locale-switcher-controller__selected--code">{{ currentLocale.iso }}</span>
      <SvgoNavigationDown class="locale-switcher-controller__selected--arrow" />
    </div>
    <Transition name="fade" mode="out-in">
      <ul v-if="isListOpened" ref="localesList" class="locale-switcher-controller__list">
        <div class="locale-switcher-controller__list-container">
          <li
            v-for="locale in locales"
            :key="locale.iso"
            class="locale-switcher-controller__list--item"
            @click="handleLocaleClick(locale)"
          >
            <UiImage v-if="locale.icon" :src="locale.icon" class="locale-switcher-controller__list--item-icon" />
            <span class="locale-switcher-controller__list--item-code">{{ locale.iso }}</span>
            <LazySvgoSelectCheckIcon
              v-if="locale.iso === currentLocale.iso"
              class="locale-switcher-controller__list--item-checked"
            />
          </li>
        </div>
      </ul>
    </Transition>
  </section>
</template>

<script setup lang="ts">
import type { TPossibleNull } from '~/types/Shared.types';
import type { ILocalizationLanguage } from '~/server/services/localization/localization.types';
import { useLocalizationStore } from '~/features/localization/store/localization.store';

const localizationStore = useLocalizationStore();
const { locales, currentLocale } = storeToRefs(localizationStore);

const isListOpened = ref(false);
const localesList = ref<TPossibleNull<HTMLElement>>(null);

const handleLocaleClick = (locale: ILocalizationLanguage) => {
  if (currentLocale.value?.iso === locale.iso) return;

  localizationStore.changeLocale(locale);
  isListOpened.value = false;
};

watch(localesList, (domElement) => {
  if (!domElement) return;
  useClickOutside(domElement, () => (isListOpened.value = false));
});
</script>

<style scoped lang="scss" src="./LocaleSwitcherController.scss" />
