import type { IBaseNotificationProps } from '~/components/BaseNotification/BaseNotification.types';
import type { IHeaderStoreApiUserOffersCount } from '~/store/header/header.types';

import { useNotificationsStore } from './notifications.store';

export const useBonusesNotificationsStore = defineStore('header/bonuses-notifications', () => {
  const notificationsStore = useNotificationsStore();
  const { sharedData } = storeToRefs(notificationsStore);

  const bonuses = ref<Omit<IBaseNotificationProps, 'size'>>({
    value: 0,
    type: 'red',
  });

  const update = (data: IHeaderStoreApiUserOffersCount) => {
    bonuses.value.value = data.promoCodesCount + data.userSocialsCount;
  };

  watch(
    sharedData,
    (newData) => {
      if (!newData?.userOffersCount) return;
      update(newData.userOffersCount);
    },
    { immediate: true, deep: true },
  );

  return { bonuses, update };
});
