<template>
  <div class="mobile-balance" :style="rootStyles">
    <div class="mobile-balance__line mobile-balance__line--money" @click="handleMoneyClick">
      <SvgoHeaderBalanceMoney class="mobile-balance__icon" />
      <BaseCurrency class="mobile-balance__amount">{{ getCurrencyWithSpaces(money) }}</BaseCurrency>
    </div>
    <div class="mobile-balance__line mobile-balance__line--coins">
      <BaseTooltip position="bottom" show-on-mobile>
        <template #default> <TooltipDescriptionCoin /> </template>
        <template #preview-icon>
          <UiImage v-if="event.icon" class="mobile-balance__icon" :src="event.icon" />
        </template>
      </BaseTooltip>
      <span class="mobile-balance__amount">{{ spaceInThousand(coins) }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IMobileBalanceProps } from './MobileBalance.types';
import { PaymentEvents } from '~/repository/amplitude/events/payment';
import { EPaymentOpenedButtonPlaces } from '~/repository/amplitude/types/payment.types';

const props = defineProps<IMobileBalanceProps>();

const rootStyles = computed(() => ({
  '--event-color': props.event.color,
}));

const handleMoneyClick = () => {
  PaymentEvents.paymentOpened({
    buttonPlace: EPaymentOpenedButtonPlaces.HEADER,
  });

  return navigateTo(useLocaleRoute()(ROUTING.PAYMENT.MAIN));
};
</script>

<style scoped lang="scss" src="./MobileBalance.scss"></style>
