<template>
  <section class="mobile-top">
    <NewHeaderLogo />
    <div v-if="!user" class="mobile-top__auth">
      <SharedKitButton
        :text="$t('header.auth')"
        :color-preset="ColorPresets.PRIMARY"
        :type-preset="TypePresets.RECTANGLE"
        :size-preset="authButtonSize"
        @click="handleStartAuth()"
      />
    </div>
    <div v-else class="mobile-top__balances">
      <NewHeaderMobileBalance
        class="mobile-top__balances-currencies"
        :money="Number(user.finance.balance)"
        :coins="Number(user.finance.coinBalance)"
        :event="{ icon: coinIcon, color: coinColor }"
      />

      <BaseCurrency class="mobile-top__balances-inventory" @click="handleInventoryClick()">
        <SvgoHeaderBalanceInventory class="mobile-top__balances-inventory-icon" />
        {{ getCurrencyWithSpaces(user.finance.totalAmountItems) }}
      </BaseCurrency>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ColorPresets, SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';

import { useAuthPopupStore } from '~/features/general-auth/store/popup.store';
import { useQsPopupStore } from '~/features/quickSale/store/qsPopup.store';
import { AuthEvents } from '~/repository/amplitude/events/auth';
import { HeaderEvents } from '~/repository/amplitude/events/header';
import { EAuthButtonPlaceClicked } from '~/repository/amplitude/types/auth.types';
import { EHeaderQuickSellPopupOpenedPlaces } from '~/repository/amplitude/types/header.types';

import { useEventStore } from '~/store/event/event.store';
import { useUserStore } from '~/store/user/user.store';

const { coinIcon, coinColor } = storeToRefs(useEventStore());
const { user } = storeToRefs(useUserStore());

const authPopupStore = useAuthPopupStore();
const qsPopupStore = useQsPopupStore();

const changeMd = GlobalUtils.Media.changeByMedia('md');

const authButtonSize = computed(() => changeMd(SizePresets.M, SizePresets.S));

const handleStartAuth = () => {
  AuthEvents.entryClicked({ 'Button Place': EAuthButtonPlaceClicked.HEADER, 'Url': location.href });
  authPopupStore.open();
};

const handleInventoryClick = () => {
  HeaderEvents.quickSellPopupOpened({ 'Button Place': EHeaderQuickSellPopupOpenedPlaces.HEADER });
  qsPopupStore.open();
};
</script>

<style scoped lang="scss" src="./MobileTopController.scss"></style>
